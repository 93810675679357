<template>
    <div class="wallet">
        <van-nav-bar title="我的钱包" left-text="返回" left-arrow @click-left="onClickLeft" />
        <div class="wallet_box">
            <div class="number">NO.{{ accountMoney.id }}</div>
            <div class="line">
                <div class="money">
                    <van-icon name="balance-pay" /> ￥ {{ accountMoney.account_money / 100 }} 元
                </div>
                <div class="recharge" bindtap="toRechargePage">
                    <van-icon name="cash-back-record" /> 充值
                </div>
            </div>
        </div>
        <div class="list_box">
            <div class="nav">
                <van-dropdown-menu>
                    <van-dropdown-item v-model="value1" :options="option1" @change="onNavChange" />
                </van-dropdown-menu>
            </div>
            <div class="list">
                <van-collapse accordion v-model="activeName">
                    <van-collapse-item :name="`${index + 1}`" v-for="(item, index) in orderData" :key="index">
                        <div slot="title">
                            <van-tag plain type="danger" custom-class="tag" v-if="item.type == 1 || item.type == 5">{{
                                item.type_label }}</van-tag>
                            <van-tag plain type="success" custom-class="tag" v-else>{{ item.type_label }}</van-tag>
                            <text style="color: #ee0a24;" v-if="item.type == 1 || item.type == 5">{{ item.type == 1 ? '-' : '+' }}
                                {{ item.money / 100 }}
                                元</text>
                            <text style="color: #07c160;" v-else>{{ item.type == 1 ? '-' : '+' }} {{ item.money / 100 }}
                                元</text>
                        </div>
                        <van-cell v-if="item.type == 3" title="充值单号：" value="{{item.order_number}}" />
                        <van-cell v-if="item.type == 3" title="充值时间：" value="{{item.created_at}}" />
                        <van-cell v-if="item.type == 2" title="退款单号：" value="{{item.order_number}}" />
                        <van-cell v-if="item.type == 2" title="退款时间：" value="{{item.created_at}}" />
                        <van-cell v-if="item.type == 1" title="消费单号：" value="{{item.order_number}}" />
                        <van-cell v-if="item.type == 1" title="消费时间：" value="{{item.created_at}}" />
                        <van-cell v-if="item.type == 4" title="奖励类型：" value="邀请消费" />
                        <van-cell v-if="item.type == 4" title="奖励时间：" value="{{item.created_at}}" />
                        <van-cell v-if="item.type == 5" title="退卡类型：" value="会员退卡" />
                        <van-cell v-if="item.type == 5" title="退卡时间：" value="{{item.created_at}}" />
                        <div style="margin-top: 25rpx;" v-if="item.type == 1 || item.type == 2">
                            <van-button type="default" block size="small" @click="toOrderPage(item)">查看详情</van-button>
                        </div>
                    </van-collapse-item>
                </van-collapse>
            </div>
        </div>
    </div>
</template>
<script>
import { onBeforeMount, ref } from 'vue';
import request from '../../utils/request';
import { useRouter } from "vue-router";
export default {
    setup() {
        const router = useRouter();
        const accountMoney = ref(0);
        const orderData = ref([]);
        const option1 = ref([{
                text: '所有记录',
                value: 0
            },
            {
                text: '消费记录',
                value: 1
            },
            {
                text: '退款记录',
                value: 2
            },
            {
                text: '充值记录',
                value: 3
            },
        ]);
        const value1 = ref(0);
        const activeName = ref(1);
        onBeforeMount(()=>{

            loadOrderData()
        });
        const loadOrderData = ()=>{
            var value1Data = value1.value
            request({
                url: '/app/user/order',
                method: 'GET',
                params: {
                    pageSize: 100,
                    status: value1Data ? value1Data + 1 : 0,
                }
            }).then((res) => {
                if (res) {
                    var resData = res.items
                    orderData.value = resData
                }
            })
        };
        const toRechargePage = ()=>{
            router.push({name:'Recharge'})
        };
        const onClickLeft = () => history.back();
        const onNavChange = ()=>{
            loadOrderData()
        };
        const toOrderPage = (item)=>{
            if(item.type!=3){
                router.push({name:'OrderPage',query:{orderid:item.id}})
            }
        };
        return{
            accountMoney,
            orderData,
            option1,
            value1,
            activeName,
            toRechargePage,
            onClickLeft,
            onNavChange,
            toOrderPage,
        }
    },
}
</script>
<style>
.wallet_box{background-color: #07C160;padding: 10px;}
.wallet_box .number{color: #ffffff;font-size: 16px;}
.wallet_box .line{display: flex;color: #ffffff;font-size: 16px;margin-top: 10px;}
.wallet_box .line .money{flex: 1;font-size: 24px;}
.wallet_box .line .recharge{}

.list .tag{margin-right: 5px;}
</style>